import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';

export async function signOutApi() {
  try {
    const result = await signOut({ global: true });
    return { data: result };
  } catch (error) {
    return {
      error: error as FetchBaseQueryError,
    };
  }
}

export async function getCurrentUserApi() {
  try {
    const user = await getCurrentUser();
    return { data: user };
  } catch (error) {
    return {
      error: error as FetchBaseQueryError,
    };
  }
}

export async function getCurrentSessionApi() {
  try {
    const session = await fetchAuthSession();
    return { data: session };
  } catch (error) {
    return {
      error: error as FetchBaseQueryError,
    };
  }
}
