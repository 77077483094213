import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../hooks/reduxStoreHooks';

export default function SupplyChainHeading() {
  const { t } = useTranslation();
  const rootCompany = useAppSelector((state) => state.user.rootCompany);
  const parentCompany = useAppSelector((state) => state.user.parentCompany);

  const isAdminCompany: boolean =
    rootCompany?.companyLevelId === 1 || rootCompany?.companyLevelId === 2;

  return (
    <>
      {!isAdminCompany && (
        <Stack direction='row'>
          <Typography variant='subtitle2'>
            {`${t('general.customer')}:`}&nbsp;
          </Typography>

          <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
            {parentCompany?.companyName}
          </Typography>
        </Stack>
      )}
    </>
  );
}
