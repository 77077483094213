import { ElementType, Suspense } from 'react';

import LoadingScreen from './LoadingScreen';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

type LoadingProps = {
  showLoadingScreen?: boolean;
};

export const Loadable = (
  Component: ElementType,
  loadingProps?: LoadingProps
) => {
  return (props: any) => (
    <Suspense fallback={<LoadingScreen {...loadingProps} />}>
      <Component {...props} />
    </Suspense>
  );
};
