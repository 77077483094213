import { Box, SxProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { m } from 'framer-motion';

import Logo from './Logo';

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

const FitScreenStyle = styled('div')(({ theme }) => ({
  zIndex: 99999,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

type Props = {
  showLoadingScreen?: boolean;
  sx?: SxProps;
};

const jsxAnimation = (
  <>
    <m.div
      initial={{ rotate: 0 }}
      animate={{ rotate: 360 }}
      transition={{
        duration: 2,
        ease: 'easeInOut',
        repeatDelay: 1,
        repeat: Infinity,
      }}
    >
      <Logo isSmall disabledLink />
    </m.div>

    <Box
      component={m.div}
      animate={{
        scale: [1.2, 1, 1, 1.2, 1.2],
        rotate: [270, 0, 0, 270, 270],
        opacity: [0.25, 1, 1, 1, 0.25],
        borderRadius: ['25%', '25%', '50%', '50%', '25%'],
      }}
      transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
      sx={{
        width: 100,
        height: 100,
        borderRadius: '25%',
        position: 'absolute',
        border: (theme) =>
          `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
      }}
    />

    <Box
      component={m.div}
      animate={{
        scale: [1, 1.2, 1.2, 1, 1],
        rotate: [0, 270, 270, 0, 0],
        opacity: [1, 0.25, 0.25, 0.25, 1],
        borderRadius: ['25%', '25%', '50%', '50%', '25%'],
      }}
      transition={{
        ease: 'linear',
        duration: 3.2,
        repeat: Infinity,
      }}
      sx={{
        width: 120,
        height: 120,
        borderRadius: '25%',
        position: 'absolute',
        border: (theme) =>
          `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
      }}
    />
  </>
);
export default function LoadingScreen({
  showLoadingScreen = false,
  ...other
}: Props) {
  return (
    <>{showLoadingScreen && <RootStyle {...other}>{jsxAnimation}</RootStyle>}</>
  );
}

export const LoadingScreenFit = () => {
  return <FitScreenStyle>{jsxAnimation}</FitScreenStyle>;
};
