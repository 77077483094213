import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AuthUser } from 'aws-amplify/auth';

import { GeoLocation, SupplyChainListCompany } from '../types/supplierAccounts';
import { BaseUserInfo, UserAccount } from '../types/userAccounts';

export type RootCompany = {
  id: number;
  companyName: string;
  geoLocation: GeoLocation;
  companyLevel: string;
  companyLevelId: number;
};

export type UserSlice = {
  cognitoUser: AuthUser | undefined;
  baseUserInfo: BaseUserInfo | null;
  userAccountData: UserAccount | null;
  baseUserIsLoading: boolean;
  rootCompany: RootCompany | null;
  parentCompany: SupplyChainListCompany | null;
};

const initialState: UserSlice = {
  cognitoUser: undefined,
  baseUserInfo: null,
  rootCompany: null,
  parentCompany: null,
  userAccountData: null,
  baseUserIsLoading: true,
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    logInUser: (state, action: PayloadAction<AuthUser>) => {
      state.cognitoUser = action.payload;
    },
    logOutUser: (state) => {
      state.cognitoUser = undefined;
      state.baseUserInfo = null;
      state.rootCompany = null;
      state.userAccountData = null;
    },
    setBaseUserInfo: (state, action: PayloadAction<BaseUserInfo>) => {
      state.baseUserInfo = action.payload;
    },
    setBaseUserInfoIsLoading: (state, action: PayloadAction<boolean>) => {
      state.baseUserIsLoading = action.payload;
    },
    setUserAccountData: (state, action: PayloadAction<UserAccount>) => {
      state.userAccountData = action.payload;
    },
    clearUserAccountData: (state) => {
      state.userAccountData = null;
    },
    setRootCompany: (state, action: PayloadAction<RootCompany | null>) => {
      state.rootCompany = action.payload;
    },
    setParentCompany: (
      state,
      action: PayloadAction<SupplyChainListCompany | null>
    ) => {
      state.parentCompany = action.payload;
    },
  },
});

export const {
  logInUser,
  logOutUser,
  setBaseUserInfo,
  setBaseUserInfoIsLoading,
  setRootCompany,
  setParentCompany,
  setUserAccountData,
  clearUserAccountData,
} = userSlice.actions;

export default userSlice.reducer;
