import { useTranslation } from 'react-i18next';

export default function useGetRoleTranslationString(roleId: number) {
  const { t } = useTranslation();

  switch (roleId) {
    case 1:
      return t('roles.superSuperAdmin');
    case 2:
      return t('roles.superAdmin');
    case 3:
      return t('roles.adminViewer');
    case 4:
      return t('roles.licensedAdmin');
    case 5:
      return t('roles.licensedMember');
    case 6:
      return t('roles.nonLicensedMember');

    default:
      return '';
  }
}
