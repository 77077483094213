import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const Button: Components<Theme> = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        boxShadow: 'none',
      },
      contained: {
        fontWeight: 'bold',
      },
    },
  },
};
