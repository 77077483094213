import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../components/guards/AuthGuard';
import GuestGuard from '../components/guards/GuestGuard';
import MainLayout from '../layouts/MainLayout';
import useGetAppRoutes from '../pages/PlotsPage/hooks/useGetAppRoutes';

import { HomePage, LoginPage } from './loadableRoutes';
import { PATH_AUTH, PATH_HOME } from './paths';

export default function Router() {
  const routes = useGetAppRoutes();

  return useRoutes([
    {
      path: PATH_AUTH.root,
      children: [
        {
          path: PATH_AUTH.login,
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: '*',
          children: [
            { path: '*', element: <Navigate to={PATH_AUTH.login} replace /> },
          ],
        },
      ],
    },
    {
      path: PATH_HOME.root,
      element: (
        <GuestGuard>
          <HomePage />
        </GuestGuard>
      ),
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: routes,
    },
  ]);
}
