import { useTranslation } from 'react-i18next';
import { enUS, srRS } from '@mui/material/locale';

import enFlag from '../assets/flags/en.svg';
import srFlag from '../assets/flags/sr.svg';
import { SystemLang } from '../types/app';

const enValue = 'en-US';

export const DEFAULT_LANG_VALUE = enValue;

export default function useGetSystemLangs() {
  const { t } = useTranslation();
  const LANGS: SystemLang[] = [
    {
      label: t('lang.English'),
      value: enValue,
      systemValue: enUS,
      icon: enFlag,
      iso2: 'GB',
    },
    {
      label: t('lang.Serbian'),
      value: 'sr-Latn-RS',
      systemValue: srRS,
      icon: srFlag,
      iso2: 'RS',
    },
  ];

  const DEFAULT_LANG_OBJECT = LANGS[0];

  return { LANGS, DEFAULT_LANG_OBJECT };
}
