import { api } from '../api';
import { API_TAGS } from '../constants';
import { useAppDispatch } from './reduxStoreHooks';
import { DEFAULT_LANG_VALUE } from './useGetSystemLangs';
import useLangs from './useLocales';

export default function useSetAppLanguage() {
  const { allLang, changeLang } = useLangs();
  const dispatch = useAppDispatch();

  return function (userLanguage: string) {
    const matchedLang = allLang.find((lang) => lang.value === userLanguage);
    if (matchedLang) {
      changeLang(matchedLang.value);
    } else {
      changeLang(DEFAULT_LANG_VALUE);
    }
    dispatch(api.util.invalidateTags([...API_TAGS])); //to refetch all that data with translated names
  };
}
