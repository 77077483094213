import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const Tooltip: Components<Theme> = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        backgroundColor: theme.palette.secondary.main,
      }),
    },
  },
};
