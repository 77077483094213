import { Box, BoxProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import LargeLogo from '../assets/LargeLogo.png';
import SmallLogo from '../assets/SmallLogo.png';

interface Props extends BoxProps {
  disabledLink?: boolean;
  width?: number;
  height?: number;
  isSmall?: boolean;
}

export default function Logo({
  isSmall = false,
  disabledLink = false,
  height = 64,
  width = 124,
  sx,
}: Props) {
  const logo = !isSmall ? (
    <Box
      component='img'
      sx={{
        borderRadius: 0.5,
        width,
        height,
        ...sx,
      }}
      src={LargeLogo}
      alt='companyLogo'
    />
  ) : (
    <Box
      component='img'
      sx={{ borderRadius: 0.5, width: 70, height: 70, ...sx }}
      src={SmallLogo}
      alt='companyLogo'
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to='/'>{logo}</RouterLink>;
}
