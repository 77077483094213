import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavItem } from '../../types/app';
import { getActiveLink } from '../../utils/getActiveLink';

type Props = {
  navItem: NavItem;
  open?: boolean;
  setClose?: () => void;
};

export default function NavItemButton({
  navItem,
  open = true,
  setClose,
}: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { label, to } = navItem;
  const isActive = getActiveLink(to, pathname);

  const handleClick = () => {
    setClose && setClose();
    navigate(to);
  };

  return (
    <ListItem
      key={label}
      disablePadding
      sx={{
        display: 'block',
        color: isActive ? 'auto' : theme.palette.secondary.main,
        borderColor: isActive ? theme.palette.primary.lighter : 'transparent',

        borderStyle: 'solid',
      }}
    >
      <ListItemButton
        onClick={handleClick}
        sx={{
          height: 32,
          px: 2.5,
          justifyContent: open ? 'initial' : 'center',
          borderRadius: 1,
        }}
      >
        <ListItemIcon
          sx={[
            {
              minWidth: 0,
              justifyContent: 'center',
              mr: open ? 3 : 'auto',
              color: isActive ? theme.palette.secondary.main : 'auto',
            },
          ]}
        >
          {<navItem.icon />}
        </ListItemIcon>
        <ListItemText
          primary={label}
          sx={{
            opacity: open ? 1 : 0,
            '& .MuiListItemText-primary': {
              fontWeight: isActive ? 'bold' : 'normal',
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}
