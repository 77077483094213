import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '../../hooks/reduxStoreHooks';
import { PATH_APP_PLOTS, PATH_APP_SELECT_COMPANY } from '../../routes/paths';

type Props = {
  children: ReactNode;
};
export default function GuestGuard({ children }: Props) {
  const location = useLocation();
  const cognitoUser = useAppSelector((state) => state.user.cognitoUser);
  const rootCompanyId = useAppSelector((state) => state.user.rootCompany?.id);

  const path = rootCompanyId
    ? PATH_APP_PLOTS.root
    : PATH_APP_SELECT_COMPANY.root;

  if (cognitoUser) {
    return <Navigate to={path} state={{ from: location }} replace />;
  }

  return <>{children}</>;
}
