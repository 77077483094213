import { Popover, PopoverProps } from '@mui/material';

import { Arrow, ArrowStyle } from './styled/ArrowStyle';

interface Props extends PopoverProps {
  arrow?: Arrow;
  disabledArrow?: boolean;
}

export default function MenuPopover({
  children,
  arrow = 'top-right',
  disabledArrow,
  sx,
  ...other
}: Props) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      slotProps={{
        paper: {
          sx: {
            p: 1,
            width: 200,
            overflow: 'inherit',
            mt: 1.5,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
            ...sx,
          },
        },
      }}
      {...other}
    >
      {!disabledArrow && <ArrowStyle arrow={arrow} />}

      {children}
    </Popover>
  );
}
