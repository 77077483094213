import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '../../hooks/reduxStoreHooks';
import { PATH_AUTH } from '../../routes/paths';

type Props = {
  children: ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const location = useLocation();
  const cognitoUser = useAppSelector((state) => state.user.cognitoUser);

  if (!cognitoUser) {
    return <Navigate to={PATH_AUTH.login} state={{ from: location }} replace />;
  }

  return <>{children}</>;
}
