import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import SatelliteIcon from '@mui/icons-material/Satellite';
import { useTranslation } from 'react-i18next';

import { PATH_APP_PLOTS, PATH_APP_USER_MANAGEMENT } from '../routes/paths';

import { NavItem } from '../types/app';
import { useAppSelector } from './reduxStoreHooks';

export default function useGetNavItems() {
  const { t } = useTranslation();
  const rootCompanyId = useAppSelector((state) => state.user.rootCompany?.id);

  if (!rootCompanyId) {
    return [];
  }

  const NavItems: NavItem[] = [
    {
      label: t('nav.plots'),
      icon: SatelliteIcon,
      to: PATH_APP_PLOTS.root,
      addDividerAfter: true,
    },

    {
      label: t('nav.companies'),
      icon: BusinessIcon,
      to: PATH_APP_USER_MANAGEMENT.companiesOverview,
    },
    {
      label: t('nav.users'),
      icon: PeopleIcon,
      to: PATH_APP_USER_MANAGEMENT.usersOverview,
      addDividerAfter: true,
    },
  ];

  return NavItems;
}
