import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../../../hooks/reduxStoreHooks';
import {
  AccountSettingsPage,
  AddPlots,
  CompanyManagementPage,
  CreateCompany,
  CreateUser,
  EditCompany,
  EditUser,
  HelpPage,
  Page404,
  PlotsPage,
  SelectedCompanyPage,
  UsersManagementPage,
} from '../../../routes/loadableRoutes';
import {
  PATH_APP_ACCOUNT_SETTINGS,
  PATH_APP_HELP,
  PATH_APP_PLOTS,
  PATH_APP_PLOTS_MANAGEMENT,
  PATH_APP_SELECT_COMPANY,
  PATH_APP_USER_MANAGEMENT,
} from '../../../routes/paths';

export default function useGetAppRoutes() {
  const rootCompanyId = useAppSelector((state) => state.user.rootCompany?.id);

  if (!rootCompanyId) {
    return [
      { path: PATH_APP_SELECT_COMPANY.root, element: <SelectedCompanyPage /> },
      { path: `*`, element: <Page404 /> },
    ];
  }

  const routes = [
    // Plots
    { path: PATH_APP_PLOTS.root, element: <PlotsPage /> },
    {
      element: <Navigate to={PATH_APP_PLOTS.root} replace />,
      index: true,
    },
    {
      path: `${PATH_APP_PLOTS_MANAGEMENT.newPlot}`,
      element: <AddPlots />,
    },
    // ACCOUNT MANAGEMENT
    //Companies
    {
      path: PATH_APP_USER_MANAGEMENT.companiesOverview,
      element: <CompanyManagementPage />,
    },
    {
      path: PATH_APP_USER_MANAGEMENT.newCompany,
      element: <CreateCompany />,
    },
    {
      path: `${PATH_APP_USER_MANAGEMENT.editCompany}/:id`,
      element: <EditCompany />,
    },
    //Users
    {
      path: PATH_APP_USER_MANAGEMENT.usersOverview,
      element: <UsersManagementPage />,
    },
    {
      path: PATH_APP_USER_MANAGEMENT.newUser,
      element: <CreateUser />,
    },
    {
      path: `${PATH_APP_USER_MANAGEMENT.editUser}/:companyId/:id/`,
      element: <EditUser />,
    },
    // Rest
    {
      path: `${PATH_APP_ACCOUNT_SETTINGS.root}`,
      element: <AccountSettingsPage />,
    },
    {
      path: `${PATH_APP_HELP.root}`,
      element: <HelpPage />,
    },
    { path: PATH_APP_SELECT_COMPANY.root, element: <SelectedCompanyPage /> },
    { path: `*`, element: <Page404 /> },
  ];

  return routes;
}
