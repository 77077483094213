import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const ListItem: Components<Theme> = {
  MuiListItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderWidth: 2,
        borderRadius: Number(theme.shape.borderRadius),
      }),
    },
  },
};
