import { useEffect } from 'react';

import {
  useActivateAccountMutation,
  useGetCurrentUserQuery,
} from '../api/userSlice';
import {
  setBaseUserInfo,
  setBaseUserInfoIsLoading,
} from '../storeSlices/userSlice';
import { useAppDispatch, useAppSelector } from './reduxStoreHooks';
import useSetAppLanguage from './useSetAppLanguage';

const useFetchAndSetBaseUserInfo = () => {
  const dispatch = useAppDispatch();
  const storeBaseUserInfo = useAppSelector((state) => state.user.baseUserInfo);
  const skipQuery = !!storeBaseUserInfo;

  const { data: baseUser, isLoading } = useGetCurrentUserQuery(undefined, {
    skip: skipQuery,
  });

  const [activateAccount] = useActivateAccountMutation();

  const setAppLanguage = useSetAppLanguage();

  useEffect(() => {
    dispatch(setBaseUserInfoIsLoading(isLoading));
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (baseUser) {
      dispatch(setBaseUserInfo(baseUser));
      setAppLanguage(baseUser.userLanguage);
    }
  }, [dispatch, baseUser, setAppLanguage]);

  useEffect(() => {
    if (baseUser) {
      activateAccount(baseUser.id);
    }
  }, [activateAccount, baseUser]);
};

export default useFetchAndSetBaseUserInfo;
