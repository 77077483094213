import { alpha, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import packageJson from '../../package.json';
import palette from '../theme/palette';

export default function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Stack
      component='footer'
      sx={{
        zIndex: 1300,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: ({ palette }) => palette.secondary.main,
        p: 1.5,
        boxShadow: `0 -1px 8px ${alpha(palette.light.secondary.main, 0.08)}`,
      }}
    >
      <Typography
        variant='caption'
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          letterSpacing: 0.5,
          fontSize: { xs: 10, sm: 12 },
          color: ({ palette }) => palette.grey[200],
        }}
      >
        {t('copyRight.main', { year: currentYear })}
        {`${t('general.version')} ${packageJson.version}`}
      </Typography>
    </Stack>
  );
}
