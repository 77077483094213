import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../hooks/reduxStoreHooks';
import useGetCompanyLevelTranslation from '../utils/useGetCompanyLevelTranslation';

export default function RootCompanyHeading() {
  const { t } = useTranslation();

  const rootCompany = useAppSelector((state) => state.user.rootCompany);
  const rootCompanyLevelTranslation = useGetCompanyLevelTranslation();

  return (
    <Stack direction='row'>
      <Typography variant='subtitle2'>
        {`${t('general.myCompany')}:`}&nbsp;
      </Typography>

      <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
        {rootCompany?.companyName}
      </Typography>

      <Typography variant='subtitle2'>
        &nbsp;
        {`(${rootCompanyLevelTranslation})`}
      </Typography>
    </Stack>
  );
}
