import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import { TOASTIFY_AUTO_CLOSE_TIME } from './constants';
import './i18n';
import { store } from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || '',
      identityPoolId: '', //for federated users identity (google, fb login etc)
      loginWith: {
        email: true,
      },
      // signUpVerificationMethod: 'code',
      userAttributes: {
        email: {
          required: true,
        },
      },
      // allowGuestAccess: true,
      passwordFormat: {
        minLength: 8,
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <ToastContainer
            position='bottom-left'
            autoClose={TOASTIFY_AUTO_CLOSE_TIME}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            theme='light'
            style={{
              width: 'fit-content',
              marginBottom: 20,
            }}
            transition={Bounce}
          />
          <App />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);
