import i18next from 'i18next';

import useGetSystemLangs from './useGetSystemLangs';

export default function useLangs() {
  const langFromStore = localStorage.getItem('lang');
  const { LANGS, DEFAULT_LANG_OBJECT } = useGetSystemLangs();

  const changeLang = (lang: string) => {
    localStorage.setItem('lang', lang);
    i18next.changeLanguage(lang);
  };

  const currentLang =
    LANGS.find((_lang) => _lang.value === langFromStore) || DEFAULT_LANG_OBJECT;

  return {
    allLang: LANGS,
    currentLang,
    changeLang: changeLang,
  };
}
