import { Stack } from '@mui/material';

import Footer from '../components/Footer';
import DesktopNav from '../components/navigation/DesktopNav';
import MobileNav from '../components/navigation/MobileNav';
import useResponsive from '../hooks/useResponsive';

export default function MainLayout() {
  const isDesktop = useResponsive('up', 'lg');
  return (
    <Stack direction='row' data-testid='main-layout' sx={{ height: '100%' }}>
      {isDesktop ? <DesktopNav /> : <MobileNav />}
      <Footer />
    </Stack>
  );
}
