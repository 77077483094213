import { api } from '.';
import {
  BaseUserInfo,
  EditChildUserData,
  EditUserRequest,
  UnsavedUserAccount,
  UserAccount,
} from '../types/userAccounts';

const usersUrl = '/UserAccount';

const userApiEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query<BaseUserInfo, void>({
      query: () => ({
        url: `${usersUrl}/currentUser`,
      }),
      providesTags: ['User'],
    }),
    getUserDataWithPermissionsForSelectedCompany: build.query<
      UserAccount,
      { companyId: number; parentCompanyId: number; accountId: number }
    >({
      query: ({ companyId, parentCompanyId, accountId }) => ({
        url: `${usersUrl}/${companyId}/${parentCompanyId}/${accountId}`,
      }),
      providesTags: ['User'],
    }),
    getCurrentUserChildren: build.query<
      UserAccount[],
      { companyId: number; supplyChainId: string }
    >({
      query: ({ companyId, supplyChainId }) => ({
        url: `${usersUrl}/children/${companyId}?supplyChainId=${supplyChainId}`,
      }),
      providesTags: ['User'],
    }),

    createUser: build.mutation<UserAccount, UnsavedUserAccount>({
      query: (body: UnsavedUserAccount) => {
        return {
          url: `${usersUrl}`,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['User'],
    }),
    editMyAccount: build.mutation<UserAccount, EditUserRequest>({
      query: (data: EditUserRequest) => {
        return {
          url: `${usersUrl}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),
    editChildUser: build.mutation<any, EditChildUserData>({
      query: (data: EditChildUserData) => {
        const { id, ...rest } = data;
        return {
          url: `${usersUrl}/child/${id}`,
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['User'],
    }),
    activateAccount: build.mutation<UserAccount, number>({
      query: (accountId: number) => {
        return {
          url: `${usersUrl}/activate/${accountId}`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['User'],
    }),

    deleteUser: build.mutation<
      UserAccount,
      { companyId: number; parentCompanyId: number; accountId: number }
    >({
      query: ({ companyId, parentCompanyId, accountId }) => {
        return {
          url: `${usersUrl}/${companyId}/${parentCompanyId}/${accountId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['User'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetCurrentUserQuery,
  useActivateAccountMutation,
  useLazyGetUserDataWithPermissionsForSelectedCompanyQuery,
  useGetUserDataWithPermissionsForSelectedCompanyQuery,
  useGetCurrentUserChildrenQuery,
  useCreateUserMutation,
  useEditMyAccountMutation,
  useDeleteUserMutation,
  useEditChildUserMutation,
} = userApiEndpoints;
