export const PAGE_SIZE_OPTIONS = [20, 50, 100];

export const PAGE_SIZE = 20;

export const PAGE = 1;

export const TOASTIFY_AUTO_CLOSE_TIME = 3000;

export const VH_FOR_PLOTS = '61vh';

export const API_TAGS = [
  'Auth',
  'Supplier',
  'User',
  'Plots',
  'Role',
  'Lookup',
] as const; //without 'as const' ts will no longer recognize api tags and throw error if you use some non existent tag
