import CssBaseline from '@mui/material/CssBaseline';

import AuthListener from './api/amplifyAuth/AuthListener';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import Router from './routes';
import AppTheme from './theme/AppTheme';

function App() {
  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <AuthListener />
      <MotionLazyContainer>
        <Router />
      </MotionLazyContainer>
    </AppTheme>
  );
}

export default App;
