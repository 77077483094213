import { Stack, StackProps, SxProps } from '@mui/material';
import { forwardRef, ReactNode } from 'react';

interface Props extends StackProps {
  children: ReactNode;
  sx?: SxProps;
}

const CustomContainer = forwardRef<HTMLDivElement, Props>(
  ({ children, sx, ...other }, ref) => (
    <>
      <Stack
        data-testit='CustomContainer - no max width'
        ref={ref}
        {...other}
        sx={{
          py: 4,
          px: 5,
          height: '100%',
          ...sx,
        }}
      >
        {children}
      </Stack>
    </>
  )
);

export default CustomContainer;
